import PropTypes from 'prop-types'
import React from 'react'
import Helmet from 'react-helmet'
import Reveal from 'react-reveal/Reveal'
import SectionHead from '../components/SectionHead'

export const FrontTemplate = () => {
  return (
    <section className='hero is-medium'>
      <SectionHead title='Site under maintenance.' />
      <div className='columns is-multiline is-vcentered'>
        <Reveal effect='fadeInUpSlow'>
          <div className='hero__content column is-10-mobile is-offset-1-mobile is-12-tablet is-4-desktop is-5-widescreen'>
            <div className='columns is-multiline'>
              <div className='column is-offset-1-tablet is-5-tablet is-11-desktop is-9-widescreen is-offset-1-desktop'>
                <p>
                  <strong>
                    Please note that Q Contemporary will be temporarily closed for maintenance and renovation works.
                  </strong>
                  We apologise for any inconvenience caused and thank you for your patience. Stay tuned!
                </p>
                <p>
                  <strong>
                    Felhívjuk figyelmüket, hogy karbantartási és felújítási munkálatok miatt ideiglenesen zárva tartunk.
                  </strong>
                  Elnézésüket kérjük az esetleges kellemetlenségekért, és köszönjük a türelmüket.
                </p>
              </div>
            </div>
          </div>
        </Reveal>
      </div>
    </section>
  )
}

const Page = () => {
  return (
    <div className='front-page'>
      <Helmet title={`Q Contemporary`} />
      <FrontTemplate />
    </div>
  )
}

Page.propTypes = {
  data: PropTypes.object.isRequired,
}

export default Page
